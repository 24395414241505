import React, { VFC, createRef, useState, useEffect } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { action } from 'mobx'
import { SimpleText } from '../../../components/SimpleText'
import { Card } from '../../payment/Card'
import { BackButton } from '../../../components/BackButton/BackButton'
import { CardToken } from '../../../type'
import Mutation from '../../../shared/mutation'
import { PaypalBtn } from '../../../shared/paypal-btn'
import Notifications from '../../../shared/notifications'
import { PAYPAL_URL } from '../../../const'
import { SimpleBox } from '../../../components/SimpleBox'
import Router from '../../../shared/router'

type Props = {
  onBackPressed: () => void
}

type CreatePaymentSourceResult = {
  createPaymentSource: boolean
}

type GeneratePaypalTokenResult = {
  generatePaypalToken: string
}

const PaymentMethodsAdd: VFC<Props> = ({ onBackPressed }) => {
  const createPaymentSourceMutation = new Mutation<CreatePaymentSourceResult>(`
    mutation createPaymentSource($input: CreatePaymentSourceInput!) {
      createPaymentSource(input: $input)
    }
  `)

  const paypalMutation = new Mutation<GeneratePaypalTokenResult>(`
    mutation generatePaypalToken {
      generatePaypalToken
    }
  `)

  const [loading, setLoading] = useState<boolean>(false)

  const [selectedTab, setSelectedTab] = useState<'paypal' | 'card'>(Router.qs.paypal ? 'paypal' : 'card')
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true)
  const cardRef = createRef<any>()

  useEffect(() => {
    if (Router.qs.paypal === 'canceled') {
      Notifications.warning('PayPal transaction was canceled', { timing: 8000 })
    }
    if (Router.qs.paypal === 'completed') {
      const paypalToken = Router.qs.token
      if (paypalToken) {
        setLoading(true)
        createPaymentSourceMutation.exec({ input: { token: paypalToken, type: 'paypal' }}).then(
          action(() => {
            setLoading(false)

            const result = createPaymentSourceMutation.data?.createPaymentSource
            if (result) {
              Notifications.success('Payment method was added')
              Router.updateHistory('/dashboard?payments=1')
              onBackPressed()
            } else {
              Notifications.error(createPaymentSourceMutation.error(), { timing: 8000 })
            }
          }))
      }
    }
  }, [Router.qs.paypal])

  const handleSubmit = () => {
    setLoading(true)

    switch(selectedTab) {
      case 'card':
        cardRef.current.tokenize().then((cardToken: CardToken) => {
          createPaymentSourceMutation.exec({ input: { token: cardToken.token, type: 'card' }}).then(
            action(() => {
              setLoading(false)

              const result = createPaymentSourceMutation.data?.createPaymentSource
              if (result) {
                Notifications.success('Payment method was added')
                onBackPressed()
              } else {
                Notifications.error(createPaymentSourceMutation.error(), { timing: 8000 })
              }
            }))
        })
        break
      case 'paypal':
        paypalMutation.exec().then(action(() => {
          setLoading(false)
          const token = paypalMutation.data?.generatePaypalToken
          if(token) {
            window.location.href = `${PAYPAL_URL}${encodeURIComponent(token)}`
          } else {
            Notifications.error(paypalMutation.error(), { timing: 8000 })
          }
        }))
        break
    }
  }

  return <>
    <Modal.Header className="payment-methods-header">
      <BackButton onBackPressed={onBackPressed} />
      <SimpleText size="large" weight="bold">Add payment method</SimpleText>
    </Modal.Header>
    <Modal.Content className="white">
      <div className="payment-methods-tabs">
        <a onClick={() => setSelectedTab('paypal')} className={selectedTab === 'paypal' ? 'active' : ''}>PayPal</a>
        <a onClick={() => setSelectedTab('card')} className={selectedTab === 'card' ? 'active' : ''}>Credit Card</a>
      </div>
      {selectedTab === 'card' && (
        <><Card
          nameDisabled
          forwardRef={cardRef}
          onValidChanged={valid => setButtonDisabled(!valid)}
        />
        <br />
        <SimpleBox paddingTop="20px" display="flex" justifyContent="center">
          <Button
            disabled={buttonDisabled}
            onClick={handleSubmit}
            content="Add credit card"
            color="blue"
            size="huge"
            primary
            loading={loading}
          />
        </SimpleBox>
        </>)}
      {selectedTab === 'paypal' && (<PaypalBtn onClick={handleSubmit} loading={loading} />)}
    </Modal.Content>
  </>
}

export { PaymentMethodsAdd }
