import React, { RefObject, useEffect, useState, VFC } from 'react'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { Button } from 'semantic-ui-react'
import { emailRegex, PAYPAL_URL } from '../../const'
import { Coupon } from '../../utils/coupon'
import Mutation from '../../shared/mutation'
import Notifications from '../../shared/notifications'
import { PaypalBtn } from '../../shared/paypal-btn'
import Router from '../../shared/router'
import Session from '../../shared/storages/session'
import { CardToken, PaypalToken, Subscribe } from '../../type'
import { PaymentData } from './ICardProps'
import { resolveCurrency } from '../../shared/format'
import Cookies from 'universal-cookie'
import {
  mutationPaypalMutation,
  mutationSubscribeMutation,
} from '../../graphql/checkout'

const cookies = new Cookies()

const ZIP_ERROR_MATCHER = '27'
const ZIP_ERROR_MESSAGE =
  'Error: The ZIP code does not match that on file with your bank. Please contact your bank to verify your zip code.'

type Props = {
  type?: 'CARD' | 'PAYPAL' | 'GOOGLE_PAY' | 'APPLE_PAY'
  data: PaymentData
  priceId: string
  cardRef: RefObject<any>
  captchaRef: RefObject<any>
  loadingState: ReturnType<typeof useState<boolean>>
  coupon?: Coupon
  disabled?: boolean
  activateNow: boolean
  promotion: boolean
  paypalReturnPath: string
  purchaseFinished?: () => void
  trialEnabled?: boolean
  checkoutSubscriptionId: string
  priceIds: string[]
  price?: number
  subscriptionId?: string
  checkoutConfigId?: string
  planDetail?: { period: number; periodUnit: number }
  handleSetShowGoogleApplePay: (show: { google: boolean, apple: boolean }) => void
}

const Submit: VFC<Props> = ({
  type = 'CARD',
  data: paymentData,
  priceId,
  cardRef,
  captchaRef,
  loadingState,
  coupon,
  disabled,
  activateNow,
  promotion,
  paypalReturnPath,
  purchaseFinished,
  checkoutSubscriptionId,
  priceIds,
  price = 0,
  subscriptionId = '',
  checkoutConfigId = '',
  planDetail,
  handleSetShowGoogleApplePay,
}) => {
  const subscribeMutation = new Mutation<Subscribe>(mutationSubscribeMutation)
  const paypalMutation = new Mutation<PaypalToken>(mutationPaypalMutation)

  const affiliateId = cookies.get('affiliateId') || ''
  const clickId = cookies.get('clickId') || ''
  const pubId = cookies.get('pubId') || ''
  const submit = (input: Hash) => {
    subscribeMutation
      .exec({ input: { ...input, checkoutSubscriptionId, priceIds, currency: resolveCurrency() } })
      .then(
        action(() => {
          const result = subscribeMutation.data?.result
          if (result) {
            Notifications.success('Account created successfully')
            Session.authenticated = true
            Session.accessToken = result.token
            purchaseFinished && purchaseFinished()

            const params: string[] = [
              `subscriptionId=${subscriptionId}`,
              `checkoutConfigId=${checkoutConfigId}`,
              `checkoutSubscriptionId=${result.subscriptionId}`,
              `period=${planDetail?.period}`,
              `periodUnit=${planDetail?.periodUnit}`,
            ]
            if (Router.qs.paypal) {
              const code = (Router.qs.paypal as string).split('|')[1] as string
              if (code) {
                params.push(`coupon=${code}`)
              }
            } else if (Router.qs.coupon) {
              params.push(`coupon=${Router.qs.coupon as string}`)
            } else if (coupon) {
              params.push(`coupon=${coupon.id}`)
            }

            if (activateNow) {
              params.push(`activateNow=${activateNow}`)
            }
            localStorage.removeItem('vs_checkout_cache')
            Router.redirect(
              `/new-welcome${params.length > 0 ? `?${params.join('&')}` : ''}`
            )
          } else {
            loadingState[1](false)
            const error = subscribeMutation.error()
            if (error.includes(ZIP_ERROR_MATCHER)) {
              Notifications.error(ZIP_ERROR_MESSAGE, { timing: 8000 })
            } else {
              Notifications.error(error, { timing: 8000 })
            }
          }
        })
      )
  }

  const submitCard = (td: CardToken) => {
    submit({
      type,
      activateNow,
      email: paymentData.email,
      firstName: paymentData.firstName,
      lastName: paymentData.lastName,
      zip: paymentData.zip,
      couponCode: coupon?.id || '',
      affiliateId,
      clickId,
      pubId,
      promotion: promotion,
      signupSource: location.pathname.split('/')[1],
      card: { token: td.token },
    })
  }

  const submitPayPal = (token: string, couponCode?: string) => {
    submit({
      type,
      activateNow,
      zip: '0000',
      email: paymentData.email,
      couponCode: coupon?.id || couponCode || '',
      affiliateId,
      clickId,
      pubId,
      promotion: promotion,
      signupSource: location.pathname.split('/')[1],
      paypal: { token },
    })
  }

  const handleChargebeeJs = () => {
    const cardTokenizer = cardRef.current
    cardTokenizer.tokenize().then((cardToken: CardToken) => {
      submitCard(cardToken)
    }).catch(() => {
      Notifications.error('The card information is invalid')
      loadingState[1](false)
    })
  }

  const handleSubmit = () => {
    if (loadingState[0]) {
      return
    }

    const captchaTokenizer = captchaRef.current
    captchaTokenizer.executeAsync().then((token: string) => {
      captchaTokenizer.reset()
      if (token && type === 'CARD') {
        loadingState[1](true)
        handleChargebeeJs()
      }
    })
  }

  const handlePayPal = () => {
    if (loadingState[0]) {
      return
    }

    loadingState[1](true)
    const payload = {
      id: priceId,
      activateNow,
      email: paymentData.email,
      coupon: coupon?.name || '',
      addons: priceIds.slice(1),
      returnPath: paypalReturnPath,
    }

    paypalMutation.exec(payload).then(() => {
      const token = paypalMutation.data?.token
      if (token) {
        window.location.href = `${PAYPAL_URL}${token}`
      } else {
        loadingState[1](false)
        Notifications.error(paypalMutation.error(), { timing: 8000 })
      }
    })
  }

  useEffect(() => {
    if (!Router.qs.paypal || (!coupon && !!Router.qs.coupon)) {
      return
    }

    const parts: string[] = (Router.qs.paypal as string).split('|')
    if (parts.at(-1) === 'completed') {
      submitPayPal(Router.qs.token as string, parts.at(1))
    } else {
      Notifications.warning('The operation on PayPal was canceled.')
    }
  }, [coupon])

  const isCard = type === 'CARD'

  const payMethodReady =
    paymentData.email !== undefined && emailRegex.test(paymentData.email)

  const cardReady =
    payMethodReady &&
    paymentData.zip !== undefined &&
    paymentData.zip !== '' &&
    paymentData.firstName !== undefined &&
    paymentData.firstName !== '' &&
    paymentData.lastName !== undefined &&
    paymentData.lastName !== ''

  return (
    <>
      {isCard && (
        <Button
          disabled={!cardReady || disabled}
          onClick={handleSubmit}
          content={activateNow ? 'Continue' : 'Start Free Trial'}
          color="red"
          size="huge"
          primary
          fluid
          loading={loadingState[0]}
        />
      )}
      {type === 'PAYPAL' && (
        <PaypalBtn
          disabled={!payMethodReady || disabled}
          onClick={handlePayPal}
          loading={loadingState[0]}
        />
      )}
    </>
  )
}

const oSubmit = observer(Submit)
export { oSubmit as Submit }
