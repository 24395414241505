import { GraphQLError } from 'graphql'
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils'
import {
  gql,
  ErrorPolicy,
  DocumentNode,
  MutationOptions,
  OperationVariables,
  FetchResult,
} from '@apollo/client'

import { computed } from '@decorators'

import { GQLClient } from '#root/config'

type Client<T> = IPromiseBasedObservable<FetchResult<T>>
type Options = MutationOptions<any, OperationVariables>
type Variables = Hash | (() => Hash)

export default class Mutation<T> implements IGQLHandler<FetchResult<T>> {
  document: DocumentNode

  variables?: Variables

  gql: Client<T>

  static exec<R>(request: DocumentNode | string, variables: Variables = {}): Client<R> {
    return (new Mutation<R>(request)).exec(variables)
  }

  constructor(request: DocumentNode | string, variables?: Variables) {
    this.document = (typeof(request) === 'string') ? gql(request) : request
    this.variables = variables
  }

  get client(): Client<T> {
    return this.gql ? this.gql : (this.exec() as any && this.gql)
  }

  @computed
  get loading() {
    return !this.client || this.client.state === 'pending'
  }

  @computed
  get loaded() {
    return this.client?.state === 'fulfilled'
  }

  @computed
  get result() {
    return this.client.value
  }

  @computed
  get status() {
    return this.client.state
  }

  @computed
  get errors() {
    return !this.loading ? this.result.errors : undefined
  }

  @computed
  get data(): T | undefined {
    return !this.loading ? this.result.data : undefined
  }

  @computed
  get hasErrors() {
    return this.hasNetworkError || (this.errors ? this.errors.length > 0 : false)
  }

  @computed
  get hasNetworkError() {
    return this.client.value.networkStatus === 8
  }

  exec(
    variables?: Variables,
    raises = false,
    settings: Partial<Exclude<Options, 'mutation'>> = {}
  ): Client<T> {
    const errorPolicy: ErrorPolicy | undefined = raises ? undefined : 'all'

    const readVars = variables || this.variables || {}
    const vars = (typeof(readVars) === 'function') ? readVars() : readVars

    const options: Options = Object.assign({ mutation: this.document, variables: vars, errorPolicy }, settings)
    return this.gql = fromPromise(GQLClient.mutate(options))
  }

  error(pos = 0): string {
    return (this.errors && this.errors.length > 0) ? (this.errors[pos] as GraphQLError)?.message : ''
  }
}
