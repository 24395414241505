import React, { FC } from 'react'
import { Image, Label } from 'semantic-ui-react'
import { Plan } from '../../../type'
import { toMoneyWithoutCalculation, toPercent } from '../../../shared/format'
import { Coupon } from '../../../utils/coupon'
import { SimpleBox } from '../../SimpleBox'
import { Feature } from '../../../types/checkout'
import Decimal from 'decimal.js'

const PlanCheckMarkIcon = require('~assets/images/icons/plan-check-mark.svg')

type Props = {
  total: string;
  coupon?: Coupon;
  activateNow: boolean;
  plan: Plan | undefined;
  userCount: number;
  selectedFeatures: Feature[];
  bienniallyFreeMonths: number;
  priceWithAddon?: number;
};

const Breakdown: FC<Props> = ({
  total,
  coupon,
  activateNow,
  plan,
  userCount,
  selectedFeatures = [],
  bienniallyFreeMonths,
  priceWithAddon,
}) => {
  const period = plan?.period || 12
  const price = parseFloat(plan?.price || '0')
  const couponAmount = coupon?.discountAmount
  const couponPercentage = coupon?.discountPercentage
  const couponDiscount = (): string => {
    if (!coupon?.discountType) {
      return toMoneyWithoutCalculation(0)
    } else if (coupon.discountType === 'FIXED_AMOUNT') {
      return toMoneyWithoutCalculation(coupon.discountAmount || 0)
    } else {
      return toPercent(coupon.discountPercentage || 0, 0)
    }
  }

  const couponValue = (): string => {
    if (!coupon?.discountType) {
      return toMoneyWithoutCalculation(0)
    } else if (coupon.discountType === 'FIXED_AMOUNT') {
      return toMoneyWithoutCalculation(coupon.discountAmount || 0)
    } else {
      return toMoneyWithoutCalculation(
        (priceWithAddon || 0) * (coupon.discountPercentage || 0)
      )
    }
  }

  const planLength =
    period === 24
      ? '2 years'
      : period === 12
        ? '1 year'
        : `${period} month${period > 1 ? 's' : ''}`

  const priceWithoutFree = (price / period) * (period + bienniallyFreeMonths)

  return (
    <div className="checkout-breakdown">
      <SimpleBox display="flex" flexDirection="column">
        <SimpleBox
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <div className="checkout-breakdown-text">{planLength}</div>
          <div className="checkout-breakdown-text">
            {toMoneyWithoutCalculation(price)}
          </div>
        </SimpleBox>
        {(period === 24 || period === 12) && activateNow && (
          <SimpleBox
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <SimpleBox
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="6px"
            >
              <p className="checkout-gray-text">
                Billed {period === 24 ? 'biennially' : 'yearly'}
              </p>
              <SimpleBox
                className="checkout-free-text"
                background="rgba(251, 0, 35, 0.10)"
                borderRadius={10}
                padding="4px 10px"
              >
                +{bienniallyFreeMonths} months free
              </SimpleBox>
            </SimpleBox>
            <p className="checkout-gray-text checkout-gray-text-crossed">
              {toMoneyWithoutCalculation(priceWithoutFree)}
            </p>
          </SimpleBox>
        )}
        {selectedFeatures.map((feature, index) => {
          const addonPriceWithDiscount = new Decimal(feature.price)
            .mul(new Decimal(1).minus(couponPercentage || 0))
            .minus(new Decimal(couponAmount || 0))
          return (
            <SimpleBox
              key={feature.chargebeeId}
              display="flex"
              flexDirection="column"
              gap="5px"
              marginTop={index === 0 ? 12 : 0}
              borderTop={index === 0 ? '1px solid #E8EAF0' : 'none'}
              borderBottom="1px solid #E8EAF0"
              padding="12px 0px"
            >
              <SimpleBox
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <SimpleBox
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap="8px"
                >
                  <Image
                    className="checkout-breakdown-icon"
                    src={PlanCheckMarkIcon}
                  />
                  <p className="checkout-breakdown-text">{feature.title}</p>
                </SimpleBox>
                <div className="checkout-breakdown-text">
                  {toMoneyWithoutCalculation(addonPriceWithDiscount.toNumber())}
                </div>
              </SimpleBox>
              {coupon && (
                <SimpleBox
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <p className="checkout-gray-text">
                    {period === 24
                      ? '2-year'
                      : period === 12
                        ? '1-year'
                        : 'monthly'}{' '}
                    plan (
                    {toMoneyWithoutCalculation(addonPriceWithDiscount
                      .div(period)
                      .toNumber()
                    )}
                    /mo)
                  </p>
                  <p className="checkout-gray-text checkout-gray-text-crossed">
                    {toMoneyWithoutCalculation(
                      parseFloat(`${new Decimal(feature.price)}` ?? 0)
                    )}
                  </p>
                </SimpleBox>
              )}
            </SimpleBox>
          )
        })}
        {coupon && (
          <SimpleBox
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mt={2}
          >
            <SimpleBox display="flex" flexDirection="column">
              <p className="checkout-breakdown-text">Discount</p>
              <SimpleBox
                display="flex"
                alignItems="center"
                gap={12}
                className="text green-text"
              >
                Coupon added{' '}
                <Label className="pill" color="green" size="tiny">
                  {couponDiscount()}
                </Label>
              </SimpleBox>
            </SimpleBox>
            <div className="text">
              <div className="text">
                {coupon ? '-' : ''}
                {couponValue()}
              </div>
            </div>
          </SimpleBox>
        )}
      </SimpleBox>
      <SimpleBox
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mt={2}
      >
        <p className="checkout-breakdown-text">Total Due Today</p>
        <div className="checkout-breakdown-text">
          {activateNow ? total : toMoneyWithoutCalculation(0)}
        </div>
      </SimpleBox>
    </div>
  )
}
export default Breakdown
