import { classNames } from '@shopify/css-utilities'
import React, {FC, ReactNode} from 'react'
import { Image } from 'semantic-ui-react'
import { Plan, PlanSellingPoint } from '../../../../type'
import { toPlanPeriod } from '../../../../utils'
import { SimpleCard } from '../../../SimpleCard'
import { Points } from './components'
import { toMoney, toPercent } from '~shared/format'
import { Selection } from '../Selection'
import {Coupon} from '../../../../utils/coupon'
import {toCouponPrice} from '../../../../shared/format'

const styles = require('./Single.module.scss')
const PlanIcon = require('~assets/images/vs_personal.svg')
const PlanIconRed = require('~assets/images/vs_family.svg')

type Comps = { Points: typeof Points }
type Props = {
  plan: Plan
  price?: number
  points: PlanSellingPoint[]
  asPair?: boolean
  footer?: ReactNode
  hidePrices?: boolean
  showPlanSelection?: boolean
  onChangePlan?: (plan: Plan) => void
  coupon?: Coupon
}

const Single: FC<Props> & Comps = ({ plan,
  points,
  asPair,
  footer,
  price,
  hidePrices,
  showPlanSelection,
  onChangePlan,
  coupon}) => {
  const stylesMain = classNames(styles.Main, { [styles.AsPair]: asPair })
  const totalMonths = plan.period * (plan.periodUnit === 'YEAR' ? 12 : 1)
  const isAnnual = totalMonths >= 12
  const planMonths = (!isAnnual && 1) || totalMonths
  const discount = (totalMonths > 12) ? 0.55 : 0.46
  const icon = (plan.externalName || '').toLowerCase().indexOf('family') >= 0 ? PlanIconRed : PlanIcon

  const totalPrice = () => toCouponPrice(price || parseFloat(plan.price), coupon)

  let spacing = 0
  if(!isAnnual) {
    spacing += 35
  }

  return (
    <SimpleCard className={stylesMain} base={asPair}>
      <div className={styles.Content}>
        <div className={'plan-header'}>
          <div className={styles.IconContainer}>
            <Image className={styles.Icon} as="img" src={icon} />
          </div>
          <div className={'plan-header-text'}>
            {/* name */}
            <div className={styles.Name}>
              <span className="text red-text">{plan.externalName}</span>
            </div>
            {/* price */}
            {hidePrices || (
              <div className={styles.Price} style={{ paddingBottom: `${spacing}px` }}>
                {toMoney(totalPrice() / planMonths)}<span className="text--large grey"> /monthly</span>
              </div>
            )}
          </div>
          {/* plan selection */}
          {showPlanSelection && (<Selection coupon={coupon} plan={plan} onChangePlan={onChangePlan} />)}
        </div>
        {/* caption */}
        {hidePrices || (
          <div className={styles.Caption} style={{ fontSize: '15', display: isAnnual ? 'block' : 'none' }}>
            <span className="text--medium grey">Billed {toPlanPeriod(plan)} - {toMoney(totalPrice())}</span>
            <span className="save">Save {toPercent(discount, 0)}</span>
          </div>
        )}
        {/* points */}
        <Points items={points} secondary={!asPair} footer={footer} />
      </div>
    </SimpleCard>
  )
}

Single.Points = Points

export { Single }
