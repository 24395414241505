import React, { FC } from 'react'
import { SimpleBox } from '../components/SimpleBox'

type activateProps = {
  months?: number;
  activateNow: boolean;
  setActivateNow: (value: boolean) => void;
  bienniallyFreeMonths?: number;
};

export const ActivateNow: FC<activateProps> = ({
  months,
  activateNow,
  setActivateNow,
  bienniallyFreeMonths = 3,
}) => {
  if (!months || months < 12) {
    return <></>
  } else {
    return (
      <SimpleBox mt={3} className="activate-now">
        <label>
          <input
            type="checkbox"
            name="activateNow"
            checked={activateNow}
            onChange={() => setActivateNow(!activateNow)}
          />{' '}
          <b>Pay today & get {bienniallyFreeMonths} months free!</b> - For a{' '}
          <b>limited time only</b>, you can choose to opt out of our 30-day
          trial and receive an additional <b>{bienniallyFreeMonths} months free</b> added to your
          subscription. That means you can get{' '}
          <b>
            {months + bienniallyFreeMonths} months for the price of {months}!
          </b>
        </label>
      </SimpleBox>
    )
  }
}
