import React, { VFC } from 'react'
import { Button, Image } from 'semantic-ui-react'
import { SimpleBox } from '../components/SimpleBox'
const Logo = require('~assets/images/paypal-logo.svg')
const GoogleLogo = require('~assets/images/google-logo.svg')
const AppleLogo = require('~assets/images/apple-logo.svg')
const Name = require('~assets/images/paypal-name.svg')

type Props = {
  disabled?: boolean;
  submited?: boolean;
  loading?: boolean;
  onClick: () => void;
};

const PaypalBtn: VFC<Props> = ({ disabled, loading, onClick }) => (
  <>
    <SimpleBox textAlign="center" my={2}>
      <Button
        color="yellow"
        onClick={onClick}
        style={{ color: 'black' }}
        size="huge"
        fluid
        disabled={disabled || loading}
        loading={loading}
      >
        Pay with <Image src={Logo} height={20} id="logo" inline />{' '}
        <Image src={Name} height={20} id="logo" inline />
      </Button>
    </SimpleBox>
  </>
)

const GoogleBtn: VFC<Props> = ({ disabled, loading, onClick }) => (
  <>
    <SimpleBox textAlign="center" my={2}>
      <Button
        color="black"
        onClick={onClick}
        style={{ color: 'white' }}
        size="huge"
        fluid
        disabled={disabled || loading}
        loading={loading}
      >
        Pay with <Image src={GoogleLogo} height={20} id="google-logo" inline />
      </Button>
    </SimpleBox>
  </>
)

const AppleBtn: VFC<Props> = ({ disabled, loading, onClick }) => (
  <>
    <SimpleBox textAlign="center" my={2}>
      <Button
        color="black"
        onClick={onClick}
        style={{ color: 'white' }}
        size="huge"
        fluid
        disabled={disabled || loading}
        loading={loading}
      >
        Pay with <Image src={AppleLogo} height={20} id="apple-logo" inline />
      </Button>
    </SimpleBox>
  </>
)
export { PaypalBtn, GoogleBtn, AppleBtn }
