import { useQuery, gql } from '@apollo/client'
import React, { FC } from 'react'
import { Plan } from '../../../../type'
import { Form } from 'semantic-ui-react'
import { SimpleText } from '../../../SimpleText'
import { SimpleBox } from '../../../SimpleBox'
import { toMoney } from '~shared/format'
import { toPlanPeriod } from '../../../../utils'
import {Coupon} from '../../../../utils/coupon'
import {toCouponPrice} from '../../../../shared/format'

const styles = require('./Selection.module.scss')

/**
 * Props for the Selection component
 */
type Props = {
  /**
   * Currently selected plan
   */
  plan: Plan

  /**
   * If any coupon applied
   */
  coupon?: Coupon

  /**
   * If component should be shown as expanded and not as a dropdown
   */
  expanded?: boolean

  /**
   * Called when user selects a different plan
   *
   * @param plan The plan that was chagned to
   */
  onChangePlan?: (plan: Plan) => void
}

/**
 * Generates a list of plans that the user can select
 * @param plans A list of plans
 * @param isFamily If current plan is a family plan
 * @param productSlug The product slug of the current plan
 * @returns
 */
const FilteredPlans = (plans: Plan[], isFamily: boolean, productSlug: string): Plan[] => plans
  .filter((value: Plan) => value.item?.metadata !== null)
  .filter((value: Plan) => typeof(value.item?.metadata) === 'object')
  .filter((value: Plan) => value.item!.metadata!.is_family === isFamily)
  .filter((value: Plan) => value.item!.metadata!.product === productSlug)
  .filter(value => [1, 12, 24].includes(value.period))

/**
 * Calcuates a title for the plan to use as option text
 * @param plan The plan to calculate a title for
 * @returns
 */
const PlanOptionTitle = (plan: Plan): string => {
  if (plan.periodUnit === 'MONTH' && plan.period >= 12) {
    const years = plan.period / 12
    return `${years} year${years === 1 ? '' : 's'}`
  }

  return `${plan.period} ${plan.periodUnit === 'MONTH' ? 'month' : 'year'}${plan.period === 1 ? '' : 's'}`
}

/**
 * Renders a selection box where user cna change plan in the checkout flow
 *
 * @param plan Then selected plan
 * @param onChangePlan A callback to call once user selects a different plan
 * @returns
 */
const Selection: FC<Props> = ({ plan, onChangePlan, coupon, expanded}) => {
  /**
   * A query to fetch all available plans
   */
  const { data } = useQuery<{ plans: Plan[] }>(gql`
    query($currency: String) {
      plans(currency: $currency) {
        id
        name
        externalName
        description
        price
        period
        periodUnit
        pricingModel
        currencyCode
        trialPeriod
        trialPeriodUnit
        billingCycles
        freeQuantity
        itemFamily { name }
        item { metadata }
        __typename
      }
    }
  `, { variables: { currency: plan.currencyCode } })

  /**
   * Returns true if current plan is of family type
   */
  const isFamily = plan.item?.metadata?.is_family || false

  /**
   * Returns current product slug
   */
  const productSlug = plan.item?.metadata?.product || ''

  /**
   * Returns filtered plans that matches the selected plan - filteredPlans
   * is of same time.
   */
  const filteredPlans: Plan[] = FilteredPlans(data?.plans || [], isFamily, productSlug)

  /**
   * Calculates the price to show taking coupoin into account
   */
  const totalPrice = () => toCouponPrice(parseFloat(plan.price), coupon)

  /**
   * Converts plans into options that can be used in the semantic-ui
   * dropdown component.
   */
  const planOptions = filteredPlans.map(value => ({key: value.id, value: value.id, text: PlanOptionTitle(value)}))

  /**
   * Finds the one month plan
   */
  const oneMonthPlan = filteredPlans.find(value => value.period === 1 && value.periodUnit === 'MONTH')

  /**
   * Calculate savings
   * @param item
   */
  const savings = (item: Plan) => {
    let period = item.period
    if (item.periodUnit === 'YEAR') {
      period = item.period * 12
    }

    const total = (oneMonthPlan?.price || 0) * period

    return Math.round(((total - toCouponPrice(item.price, coupon)) / total ) * 100)
  }

  /**
   * Generates expanded markup
   */
  const expandedMarkup = <>{filteredPlans.map(item => {
    const savingAmount = savings(item)

    return (<div key={item.id}
      onClick={() => { onChangePlan?.(item) }}
      className={`${styles.expandedContainer} ${item.id === plan.id ? styles.active : styles.inactive}`}>
      <div className={styles.expandedPlanInfo}>
        {savingAmount > 0 && <div className={styles.expandedPlanInfoSavings}>Save an extra {savingAmount}% with this plan!</div>}
        <div className={styles.expandedPlanInfoTitle}>
          {item.name}
        </div>
        <div>
          {isFamily ? '5 users' : '1 user'} - Billed {toPlanPeriod(item)} at {toMoney(toCouponPrice(item.price, coupon))}
        </div>
      </div>
      <div className={styles.price}>
        {toMoney(toCouponPrice(item.price / item.period, coupon))}/mo
      </div>
    </div>)
  })}</>

  return (<SimpleBox textAlign="left" marginTop={`${expanded ? '' : '10px'}`} className="plan-selection">
    {expanded && expandedMarkup}
    {!expanded && (<>
      <SimpleText className="billed">Billed</SimpleText>
      <Form.Dropdown
        fluid
        selection
        required
        value={plan.id}
        onChange={(event, _data) => { onChangePlan?.(filteredPlans?.filter(value => value.id === _data.value)[0]) }}
        options={planOptions}
      />
      <SimpleBox className="description">
        {isFamily ? '5 users' : '1 user'} - Billed {toPlanPeriod(plan)} - {toMoney(totalPrice())}
      </SimpleBox></>)}
  </SimpleBox>)
}

export { Selection }
