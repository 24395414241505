/* eslint-disable complexity */
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import { set } from 'dot-prop'
// eslint-disable-next-line no-use-before-define
import React, { FC, useEffect, useRef, useState } from 'react'
import {
  Form,
  FormField,
  Grid,
  GridRow,
  Label,
  Modal,
} from 'semantic-ui-react'
import { SimpleBox } from '../../components/SimpleBox'
import { SimpleCard } from '../../components/SimpleCard'
import { SimpleForm } from '../../components/SimpleForm'
import { SimpleText } from '../../components/SimpleText'
import { observer } from '../../decorators'
import { Submit } from '../../fragments/payment/SubmitNew'
import { toMoney } from '../../shared/format'
import Router from '../../shared/router'
import { ChargebeeItemPrice } from '../../shared/types'
import { Plan } from '../../type'
import { useNavigation } from '../../hooks/useNavigation'
import { DiscountWarning } from '../../fragments/modal/payment/DiscountWarning'
import { Coupon, CouponData } from '../../utils/coupon'
import { ActivateNow } from '../../fragments/ActivateNow'
import { PaymentData } from '../../fragments/payment/ICardProps'
import { CardComponent } from '../../fragments/payment/CardComponent'
import { useSiteSetting } from '../../hooks/useSiteSetting'
import {
  trackBeginCheckout,
  trackEmailValidationSuccess,
  trackPurchase,
} from '../../utils/tracking'
import { emailRegex } from '../../const'
import PaymentMethod from '../../components/Checkout/PaymentMethod'
import AddonCard from '../../components/Checkout/AddonCard'
import SummaryCard from '../../components/Checkout/SummaryCard'
import Tags from '../../components/Checkout/Tags'
import {
  IExtraFeature,
  applyCoupon,
  createCheckoutSubscription,
  queryPlan,
  queryUpsellItems,
  parsePayPalReturnData,
} from '../../graphql/checkout'
import { isEmailValid, parseStringToCamelCase } from '../../utils'
import Cookies from 'universal-cookie'
import {
  Feature,
  CreateCheckoutSubscriptionResult,
  queryCheckoutUpsellsResult,
} from '../../types/checkout'
import Session from '../../shared/storages/session'
import Decimal from 'decimal.js'

const PLAN_PERIOD_MAP = {
  DAY: 0,
  WEEK: 1,
  MONTH: 2,
  YEAR: 3,
}

const cookies = new Cookies()

const PaymentPayPalIcon = require('~assets/images/icons/payment-paypal.svg')
const PaymentCardsImage = require('~assets/images/payment-cards.svg')
const PaymentGoogleImage = require('~assets/images/payment-google.svg')
const PaymentAppleImage = require('~assets/images/payment-apple.svg')
type PriceProperties = 'id' | 'name' | 'externalName' | 'price' | 'trialPeriod';
export type Price = Pick<ChargebeeItemPrice, PriceProperties>;
type Result = { plan: Plan };

export type CardDetails = { number: string, expiry: string, cvv: string, token?: string }
type CouponWarningProps = { coupon: Coupon, plan: Plan }

const CheckoutProduct: FC & { authorize: boolean; auth: string } = (props: {
  children?: React.ReactNode;
  trialEnabled: boolean;
  promotion: boolean;
}) => {
  const affiliateId = cookies.get('affiliateId') || ''
  const forceAuthorizeOne = useSiteSetting('FORCE_AUTHORIZE_ON_ONE_PLANS')
  const cardRef = useRef<any>()
  const captchaRef = useRef<any>()
  const { setNav, resetNav } = useNavigation()
  const price = Router.params.price as string
  const priceIds = price && price.includes(':') ? price.split(':') : [price]
  const [firstPlanId, setFirstPlanId] = useState<string>(priceIds[0])
  const [firstLoading, setFirstLoading] = useState<boolean>(true)
  const paypalData = parsePayPalReturnData()
  const [activateNow, setActivateNow] = useState<boolean>(paypalData.activateNow)
  const loadingState = useState<boolean>(paypalData.result === 'completed')
  const [coupon, setCoupon] = useState<Coupon | undefined>(undefined)
  const [couponWarningPayload] = useState<CouponWarningProps>()
  const [modal, setModal] = useState<'discount-warning' | undefined>(undefined)
  const [cardErrors, setCardErrors] = useState<string[]>([])
  const [data, setData] = useState<PaymentData>({
    email: paypalData.email,
    firstName: '',
    lastName: '',
    zip: '',
    card: { number: '', expiry: '', cvv: '' },
    paypal: {},
    captcha: false,
  })
  const [paymentMethod, setPaymentMethod] = useState<
    'CARD' | 'PAYPAL' | 'GOOGLE_PAY' | 'APPLE_PAY' | undefined
  >(Router.qs.paypal ? 'PAYPAL' : 'CARD')
  const [addons, setAddons] = useState<Array<string>>(paypalData.addons || [])
  const [showGoogleApplePay, setShowGoogleApplePay] = useState<{
    google: boolean;
    apple: boolean;
  }>({
    google: false,
    apple: false,
  })
  const [emailError, setEmailError] = useState<boolean>(false)

  const { data: planData, loading } = useQuery<Result>(queryPlan, { variables: { id: firstPlanId } })

  const plan = planData?.plan
  const isFamily = plan?.name.toLowerCase().includes('family')
  const months = (plan?.period || 0) * (plan?.periodUnit === 'YEAR' ? 12 : 1)
  const isOne = plan?.item?.metadata?.product === 'one'

  const [fetchUpsellItems, { data: upsellItems, loading: loadingUpsellItems }] =
    useLazyQuery<queryCheckoutUpsellsResult>(queryUpsellItems)
  const [
    createCheckoutSubscriptionMutation,
    { loading: loadingCheckoutConfig, data: checkoutSubscriptionData },
  ] = useMutation<CreateCheckoutSubscriptionResult>(createCheckoutSubscription)

  const [getCouponData, { data: couponData }] =
    useMutation<CouponData>(applyCoupon)

  useEffect(() => {
    createCheckoutSubscriptionMutation({
      variables: { affiliateId },
    })
  }, [])

  const checkoutConfig = checkoutSubscriptionData?.createCheckoutSubscription

  useEffect(() => {
    if (firstLoading) {
      setFirstLoading(false)
      const checkoutDataCache = JSON.parse(
        localStorage.getItem('vs_checkout_cache') ?? '{}'
      )
      if (Object.keys(checkoutDataCache).length > 0) {
        setData(checkoutDataCache.data)
        setPaymentMethod(
          checkoutDataCache.type === 'PAYPAL' ? 'PAYPAL' : 'CARD'
        )
        if (checkoutDataCache.coupon) {
          setCoupon(checkoutDataCache.coupon)
        }
        if (checkoutDataCache.activateNow !== undefined) {
          setActivateNow(checkoutDataCache.activateNow)
        }
      }
    }
    setNav('no-sign-in')
    return () => {
      resetNav()
    }
  }, [])

  useEffect(() => {
    localStorage.setItem(
      'vs_checkout_cache',
      JSON.stringify({
        id: firstPlanId,
        coupon,
        activateNow,
        data,
        type: paymentMethod,
      })
    )
  }, [data, coupon, firstPlanId, activateNow, paymentMethod])

  const cartSteps = checkoutConfig?.checkoutConfiguration?.cartSteps || []

  const cartExtraFeatures = (
    checkoutConfig?.checkoutConfiguration?.cartExtraFeatures || []
  ).map(feature => {
    const addon = upsellItems?.addons.find(addon => addon.itemId === feature.chargebeeId)
    return { ...feature, price: parseFloat(addon?.price || '0') }
  })

  const extraUserAddon = upsellItems?.addons.find(addon => addon.itemId === 'k5i47h4q')
  const addAnotherUser = extraUserAddon && addons.includes(extraUserAddon.id)
  const selectedFeatures: Feature[] = cartExtraFeatures.filter(feature => addons.includes(feature.chargebeeId))
  addAnotherUser && selectedFeatures.unshift({
    chargebeeId: extraUserAddon.id,
    title: extraUserAddon.name,
    price: extraUserAddon.price,
  })

  const trialEnabled =
    props.trialEnabled &&
    checkoutConfig?.checkoutConfiguration?.payNowEnabled &&
    Router.qs.noTrial === undefined

  const handleClosePaymentMethodCard = () => {
    setPaymentMethod(undefined)
  }

  const addonsTotalPrice = addons.reduce((acc, addon) => {
    const feature = cartExtraFeatures.find(f => f.chargebeeId === addon)
    return acc + (feature?.price || 0)
  }, 0)

  const priceWithAddon = new Decimal(plan?.price || '0')
    .add(new Decimal((addAnotherUser && extraUserAddon?.price) || '0'))
    .add(new Decimal(addonsTotalPrice))

  const discount = () => {
    if (!coupon?.discountType) {
      return 0
    } else if (coupon.discountType === 'FIXED_AMOUNT') {
      return Number(coupon.discountAmount)
    } else {
      return priceWithAddon.mul(new Decimal(coupon.discountPercentage || 0))
    }
  }

  const priceTotal = priceWithAddon.minus(discount())

  const handleDataChange = ({ name, value }: { name: string; value: any }) => {
    if (name === 'firstName' || name === 'lastName') {
      if (
        value !== '' &&
        !/^[A-Za-z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`]+$/g.test(value)
      ) {
        return
      }
    }
    setData(d => ({ ...set(d, name, value) }))
  }

  const handleEmailChange = (value: string) => {
    const regex = emailRegex
    const isValid = regex && regex.test(value)
    if (isValid) {
      trackEmailValidationSuccess()
    }

    setData(d => ({ ...set(d, 'email', value) }))
  }

  useEffect(() => { setActivateNow(!trialEnabled) }, [trialEnabled])

  useEffect(() => {
    if (checkoutConfig?.checkoutConfiguration?.defaultCoupon) {
      getCouponData({
        variables: {
          coupon: checkoutConfig?.checkoutConfiguration?.defaultCoupon,
          planId: firstPlanId,
        },
      })
    }
  }, [checkoutConfig?.checkoutConfiguration?.defaultCoupon, firstPlanId])

  useEffect(() => {
    if (couponData && couponData.coupon) {
      setCoupon(couponData.coupon)
    }
  }, [couponData])

  const handleCouponFound = (coupon: Coupon) => {
    setCoupon(coupon)
    cookies.set('coupon', coupon.name, { path: '/' })
    if (plan && coupon) {
      trackBeginCheckout(plan, isFamily, coupon, 'add coupon')
    }
  }

  const handleRemoveCoupon = () => {
    setCoupon(undefined)
    if (plan) {
      trackBeginCheckout(plan, isFamily, undefined, 'remove coupon')
    }
  }

  const linkMarkup = (path: string, text: string) => (
    <a
      href={`https://virtualshield.com/${path}`}
      target="_blank"
      rel="noreferrer"
    >
      {' '}
      {text}
    </a>
  )

  const applyWarnedCoupon = () => {
    if (couponWarningPayload) {
      const path = location.pathname.split('/')[1]
      Router.updateHistory(`/${path}/${couponWarningPayload.plan.id}`)
      setFirstPlanId(couponWarningPayload.plan.id)
      setCoupon(undefined)
      setModal(undefined)
    }
  }

  const handleSelectAddon = (addonId: string) => {
    if (addons.includes(addonId)) {
      setAddons(addons.filter(id => id !== addonId))
    } else {
      setAddons(addons.concat(addonId))
    }
  }

  const handleToggleAddUser = () => {
    !loadingState[0] && extraUserAddon && handleSelectAddon(extraUserAddon.id)
  }

  const buttonDisabled = paymentMethod === 'CARD' && cardErrors.length > 0

  if (!plan && !loading) {
    Router.redirect('/pricing')
  }

  useEffect(() => {
    if (plan) {
      fetchUpsellItems({ variables: { period: plan.period, periodUnit: PLAN_PERIOD_MAP[plan.periodUnit] } })
      !Router.qs.coupon && trackBeginCheckout(plan, isFamily, coupon, 'plan')
    }
  }, [plan])

  useEffect(() => {
    if (coupon && plan && Router.qs.coupon) {
      trackBeginCheckout(plan, isFamily, coupon, 'coupon')
    }
  }, [coupon])

  useEffect(() => {
    plan?.currencyCode && (Session.currency = plan?.currencyCode)
  }, [plan?.currencyCode])

  const handleEmailInputBlur = () => {
    if (data.email.length > 0 && !isEmailValid(data.email)) {
      setEmailError(true)
    }
  }

  if (
    loadingCheckoutConfig ||
    loading ||
    loadingUpsellItems
  ) {
    return <div>loading</div>
  }

  const submitMarkup = () => (
    <>
      {trialEnabled && (
        <ActivateNow
          months={months}
          activateNow={activateNow}
          setActivateNow={setActivateNow}
          bienniallyFreeMonths={checkoutConfig?.checkoutConfiguration?.freeMonths}
        />
      )}
      <SimpleBox mt={3} className="submit-box">
        <Submit
          checkoutSubscriptionId={checkoutConfig?.id || ''}
          priceIds={[firstPlanId].concat(addons)}
          paypalReturnPath={`/${location.pathname.split('/')[1]}`}
          type={paymentMethod}
          data={data}
          priceId={firstPlanId}
          cardRef={cardRef}
          loadingState={loadingState}
          coupon={coupon}
          disabled={buttonDisabled}
          activateNow={activateNow}
          promotion={activateNow && props.promotion}
          captchaRef={captchaRef}
          purchaseFinished={() => plan && trackPurchase(plan, isFamily, activateNow, coupon)}
          price={priceTotal.toNumber()}
          subscriptionId={checkoutConfig?.id}
          checkoutConfigId={checkoutConfig?.checkoutConfiguration?.id}
          planDetail={{
            period: plan?.period || 0,
            periodUnit: PLAN_PERIOD_MAP[plan?.periodUnit || 'MONTH'],
          }}
          handleSetShowGoogleApplePay={setShowGoogleApplePay}
        />
      </SimpleBox>
      <SimpleBox mt={3}>
        <div className="text--x-small">
          By clicking Complete Purchase, you agree to our
          {linkMarkup('legal/terms/', 'Terms of Service')} and acknowledge our
          {linkMarkup('legal/privacy/', 'Privacy Policy')}. To ensure
          uninterrupted access, your subscription will automatically renew at
          the end of each billing cycle using the payment method provided until
          you cancel it by disabling auto-renewal, which can be done at any
          time.
        </div>
      </SimpleBox>
    </>
  )

  const steps: any = {
    email: {
      title: (
        <SimpleText size="title3">
          Enter Your Email Address
          <span className="enter-email" />
        </SimpleText>
      ),
      body: (
        <SimpleBox mt={3}>
          <SimpleCard fluid padding="heavy">
            <FormField>
              <label htmlFor="email">Enter email address</label>
              <Grid style={{ margin: 0 }}>
                <GridRow verticalAlign="middle" className="email-input-row">
                  <SimpleForm.Input
                    type="email"
                    value={data?.email}
                    onChange={(d: any) => handleEmailChange(d.value)}
                    size="large"
                    required
                    fluid
                    showValidation={emailError}
                    handleInputBlur={handleEmailInputBlur}
                  />
                </GridRow>
                <GridRow verticalAlign="middle">
                  <div className="text--x-small">
                    We do not share your information and will contact you only
                    as needed to provide our service.
                  </div>
                </GridRow>
              </Grid>
            </FormField>
          </SimpleCard>
        </SimpleBox>
      ),
    },
    extraFeatures: {
      title: (
        <>
          <SimpleText size="title3">Add Extra Protection</SimpleText>
          <Label
            style={{
              background: '#E8EAF0',
              padding: '0px !important',
              height: '20px',
              width: '60px',
              borderRadius: '18px !important',
            }}
          >
            <SimpleText color="grey" size="textSmall">
              Optional
            </SimpleText>
          </Label>
        </>
      ),
      body: (
        <SimpleBox className="addon-card-row">
          <SimpleBox
            mt={3}
            display="flex"
            flexDirection="row"
            className="addon-row"
          >
            {cartExtraFeatures
              ?.sort((a: IExtraFeature, b: IExtraFeature) => a.position > b.position ? 1 : -1
              )
              .slice(0, 3)
              .map((feature: IExtraFeature) => (
                <AddonCard
                  key={feature.id}
                  title={feature.title}
                  description={feature.description}
                  icon={feature.imageUrl}
                  price={new Decimal(feature.price).div(plan?.period || 12)}
                  handleClickOnAdd={event => {
                    event.preventDefault()
                    handleSelectAddon(feature.chargebeeId)
                    event.currentTarget.blur()
                  }}
                  isSelected={addons.includes(feature.chargebeeId)}
                  coupon={coupon}
                  planPeriod={plan?.period || 12}
                />
              ))}
          </SimpleBox>
        </SimpleBox>
      ),
    },
    paymentMethods: {
      title: (
        <SimpleText size="title3">
          Select Your Preferred Payment Method
        </SimpleText>
      ),
      body: {
        creditCard: (
          <PaymentMethod
            title="Credit Card"
            icon={
              <img
                height={20}
                src={PaymentCardsImage}
                alt="cards"
                className="payment-icon"
              />
            }
            isCardOpen={paymentMethod === 'CARD'}
            handleToggleCard={() => {
              if (paymentMethod === 'CARD') {
                handleClosePaymentMethodCard()
                return
              }
              setPaymentMethod('CARD')
            }}
          >
            <SimpleBox
              marginTop={25}
              paddingTop={18}
              borderTop="1px solid #E8EAF0"
            >
              <CardComponent
                data={data}
                forwardRef={cardRef}
                captchaRef={captchaRef}
                onChange={handleDataChange}
                onErrors={setCardErrors}
                forceAuthorize={isOne && forceAuthorizeOne.enabled}
              />
              {submitMarkup()}
            </SimpleBox>
          </PaymentMethod>
        ),
        paypal: (
          <PaymentMethod
            title="PayPal"
            icon={
              <img
                height={20}
                src={PaymentPayPalIcon}
                alt="paypal"
                className="payment-icon"
              />
            }
            isCardOpen={paymentMethod === 'PAYPAL'}
            handleToggleCard={() => {
              if (paymentMethod === 'PAYPAL') {
                handleClosePaymentMethodCard()
                return
              }
              setPaymentMethod('PAYPAL')
            }}
          >
            <SimpleBox
              marginTop={25}
              paddingTop={18}
              borderTop="1px solid #E8EAF0"
            >
              {submitMarkup()}
            </SimpleBox>
          </PaymentMethod>
        ),
        googlePay: showGoogleApplePay.google ? (
          <PaymentMethod
            title="Google Pay"
            icon={
              <div className="payment-icon-box">
                <img height={12} src={PaymentGoogleImage} alt="google" />
              </div>
            }
            isCardOpen={paymentMethod === 'GOOGLE_PAY'}
            handleToggleCard={() => {
              if (paymentMethod === 'GOOGLE_PAY') {
                handleClosePaymentMethodCard()
                return
              }
              setPaymentMethod('GOOGLE_PAY')
            }}
          >
            <SimpleBox
              marginTop={25}
              paddingTop={18}
              borderTop="1px solid #E8EAF0"
            >
              {submitMarkup()}
            </SimpleBox>
          </PaymentMethod>
        ) : null,
        applePay: showGoogleApplePay.apple ? (
          <PaymentMethod
            title="Apple Pay"
            icon={
              <div className="payment-icon-box">
                <img height={10} src={PaymentAppleImage} alt="apple" />
              </div>
            }
            isCardOpen={paymentMethod === 'APPLE_PAY'}
            handleToggleCard={() => {
              if (paymentMethod === 'APPLE_PAY') {
                handleClosePaymentMethodCard()
                return
              }
              setPaymentMethod('APPLE_PAY')
            }}
          >
            <SimpleBox
              marginTop={25}
              paddingTop={18}
              borderTop="1px solid #E8EAF0"
            >
              {submitMarkup()}
            </SimpleBox>
          </PaymentMethod>
        ) : null,
      },
    },
  }

  const renderSummaryCard = () => (
    <SummaryCard
      total={toMoney(priceTotal.toNumber())}
      coupon={coupon}
      activateNow={activateNow}
      plan={plan}
      extraUserAddon={extraUserAddon}
      userCount={addAnotherUser ? 2 : 1}
      firstPlanId={firstPlanId}
      handleToggleAddUser={handleToggleAddUser}
      handleCouponFound={handleCouponFound}
      handleRemoveCoupon={handleRemoveCoupon}
      selectedFeatures={selectedFeatures}
      bienniallyFreeMonths={checkoutConfig?.checkoutConfiguration?.freeMonths || 3}
      priceWithAddon={priceWithAddon.toNumber()}
    />
  )

  return (
    <fieldset disabled={loadingState[0]} style={{ margin: 0, padding: 0, border: 'none' }}>
      {/* modal */}
      <Modal
        closeIcon
        size="tiny"
        open={modal !== undefined}
        onClose={() => setModal(undefined)}
      >
        <DiscountWarning
          isOpen={modal === 'discount-warning'}
          payload={couponWarningPayload}
          onFinish={() => applyWarnedCoupon()}
        />
      </Modal>
      <div className="checkout-title">
        <p>|</p>
        <SimpleText size="title2" color="grey">
          Checkout
        </SimpleText>
      </div>
      <Tags
        trialEnabled={trialEnabled}
        activateNow={activateNow}
        reduced={checkoutConfig?.checkoutConfiguration?.reducedMoneyBackGuarantee}
      />
      <SimpleBox mt={19} className="checkout">
        <SimpleBox
          maxWidth={1280}
          mx="auto"
          display="flex"
          flexDirection="row"
          gap={21}
          justifyContent="center"
        >
          {!loadingCheckoutConfig && cartSteps?.length > 0 && (
            <Form name="checkout" className="checkout-first-block">
              {/* account */}
              <SimpleBox
                display="flex"
                alignItems="center"
                gap={12}
                width="100%"
              >
                <SimpleCard
                  color="cornflower"
                  style={{ flex: '0 0 32px' }}
                  base
                  rounded="circle"
                >
                  <SimpleBox
                    height={32}
                    width={32}
                    display="grid"
                    alignContent="center"
                  >
                    <SimpleText size="title2" color="white">
                      1
                    </SimpleText>
                  </SimpleBox>
                </SimpleCard>
                {steps[parseStringToCamelCase(cartSteps[0].stepType) as string].title}
              </SimpleBox>
              {steps[parseStringToCamelCase(cartSteps[0].stepType) as string].body}
              <div className="checkout-summary-mobile">
                <SimpleBox
                  display="flex"
                  alignItems="center"
                  gap={12}
                  width="100%"
                >
                  <SimpleCard
                    color="cornflower"
                    style={{ flex: '0 0 32px' }}
                    base
                    rounded="circle"
                  >
                    <SimpleBox
                      height={32}
                      width={32}
                      display="grid"
                      alignContent="center"
                    >
                      <SimpleText size="title2" color="white">
                        2
                      </SimpleText>
                    </SimpleBox>
                  </SimpleCard>
                  <SimpleText size="title3">Selected Plan</SimpleText>
                </SimpleBox>
                {renderSummaryCard()}
              </div>
              {cartSteps.slice(1).map((step: any, index: number) => {
                const currentStepName = parseStringToCamelCase(step.stepType)
                const isCurrentStepPaymentMethods = step.stepType === 'payment_methods'
                return (
                  <React.Fragment key={index}>
                    <SimpleBox
                      mt={5}
                      display="flex"
                      alignItems="center"
                      gap={12}
                      width="100%"
                    >
                      <SimpleCard
                        color="cornflower"
                        style={{ flex: '0 0 32px' }}
                        base
                        rounded="circle"
                      >
                        <SimpleBox
                          height={32}
                          width={32}
                          display="grid"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <div className="hide-on-tablet-down">
                            <SimpleText size="title2" color="white">
                              {index + 2}
                            </SimpleText>
                          </div>
                          <div className="show-on-tablet-down">
                            <SimpleText size="title2" color="white">
                              {index + 3}
                            </SimpleText>
                          </div>
                        </SimpleBox>
                      </SimpleCard>
                      {steps[currentStepName].title}
                    </SimpleBox>
                    {isCurrentStepPaymentMethods ? (
                      <>
                        {checkoutConfig?.checkoutConfiguration.cartPaymentMethods.map(
                          (paymentMethod: any) => (
                            <React.Fragment key={paymentMethod.id}>
                              {
                                steps.paymentMethods.body[
                                  parseStringToCamelCase(
                                    paymentMethod.paymentMethod
                                  ) as string
                                ]
                              }
                            </React.Fragment>
                          )
                        )}
                      </>
                    ) : (
                      steps[currentStepName].body
                    )}
                  </React.Fragment>
                )
              })}
            </Form>
          )}
          {plan && (
            <SimpleBox className="order-summary-desktop">
              <SimpleBox
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                height={32}
                marginBottom={18}
              >
                <SimpleText size="title3">Order Summary</SimpleText>
              </SimpleBox>
              {renderSummaryCard()}
            </SimpleBox>
          )}
        </SimpleBox>
      </SimpleBox>
    </fieldset>
  )
}

CheckoutProduct.defaultProps = {
  trialEnabled: true,
  promotion: true,
}

CheckoutProduct.authorize = false
CheckoutProduct.auth = '/apps'

export default observer(CheckoutProduct)
