import React, { useMemo } from 'react'
import { Button, Image } from 'semantic-ui-react'
import { Coupon } from '../../../utils/coupon'
import Decimal from 'decimal.js'

interface AddonCardProps {
  title: string;
  description: string;
  price: Decimal;
  icon: string;
  handleClickOnAdd?: (event: React.MouseEvent<HTMLElement>) => void;
  isSelected: boolean;
  coupon?: Coupon;
  planPeriod:number;
}

const AddonCard: React.FC<AddonCardProps> = ({
  title,
  description,
  icon,
  price,
  handleClickOnAdd,
  isSelected,
  coupon,
  planPeriod
}) => {
  const { originalPrice, priceWithDiscount, discount } = useMemo(() => {
    const originalPrice = price.toFixed(2)
    if (!coupon) {
      return {
        originalPrice,
        priceWithDiscount: price.toFixed(2),
        discount: null,
      }
    }
    if (coupon.discountType === 'PERCENTAGE') {
      const priceWithDiscount = price
        .mul(new Decimal(1).sub(coupon.discountPercentage || 0))
        .toFixed(2)
      return {
        originalPrice,
        priceWithDiscount,
        discount: `${new Decimal(coupon.discountPercentage || 0)
          .mul(100)
          .toNumber()}%`,
      }
    }
    if (coupon.discountType === 'FIXED_AMOUNT') {
      const discountValue = new Decimal(coupon.discountAmount || 0).div(planPeriod)
      const priceWithDiscount = price
        .sub(discountValue)
        .toFixed(2)
      return {
        originalPrice,
        priceWithDiscount,
        discount: `$${discountValue.toFixed(2)}`,
      }
    }
    return { originalPrice, priceWithDiscount: originalPrice, discount: null }
  }, [coupon, price])
  return (
    <div className={`addon-card${isSelected ? ' addon-card-selected' : ''}`}>
      <div className="detail">
        <div className="description">
          <div className="title">{title}</div>
          <div className="subTitle">{description}</div>
          {coupon ? (
            <div className="price-discount-box">
              <div className="price">
                for <span>${priceWithDiscount}</span>/mo
              </div>
              <div className="original-price-row">
                <div className="original-price">${originalPrice}/mo</div>
              </div>
            </div>
          ) : (
            <div className="price">
              for <span>${originalPrice}</span>/month
            </div>
          )}
        </div>
        <div className="logo">
          <Image as="img" src={icon} />
        </div>
      </div>
      <Button
        className={`addon-button${isSelected ? '-selected' : ''}`}
        onClick={handleClickOnAdd}
      >
        {isSelected ? 'Added' : 'Add'}
      </Button>
    </div>
  )
}

export default AddonCard
