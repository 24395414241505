import { action } from 'mobx'
import { observer } from 'mobx-react'
import React, { RefObject, useEffect, useState, VFC } from 'react'
import { Button } from 'semantic-ui-react'
import { Settings } from '../../config'
import { emailRegex, PAYPAL_URL } from '../../const'
import { Coupon } from '../../utils/coupon'
import Mutation from '../../shared/mutation'
import Notifications from '../../shared/notifications'
import { PaypalBtn } from '../../shared/paypal-btn'
import Router from '../../shared/router'
import Session from '../../shared/storages/session'
import { CardToken, PaypalToken } from '../../type'
import {PaymentData} from './ICardProps'
import { resolveCurrency } from '../../shared/format'
import Cookies from 'universal-cookie'

type Subscribe = { result: { token: string, subscriptionId: string, finalPrice: number } }

const cookies = new Cookies()

const ZIP_ERROR_MATCHER = '27'
const ZIP_ERROR_MESSAGE = 'Error: The ZIP code does not match that on file with your bank. Please contact your bank to verify your zip code.'

type Props = {
  type: 'CARD' | 'PAYPAL'
  data: PaymentData
  priceId: string
  cardRef: RefObject<any>
  captchaRef: RefObject<any>
  coupon?: Coupon
  disabled?: boolean
  activateNow: boolean
  promotion: boolean
  paypalReturnPath: string
  purchaseFinished?: () => void
}

const Submit: VFC<Props> = ({
  type,
  data: paymentData,
  priceId,
  cardRef,
  captchaRef,
  coupon,
  disabled,
  activateNow,
  promotion,
  paypalReturnPath,
  purchaseFinished
}) => {
  const subscribeMutation = new Mutation<Subscribe>(`mutation($input: SubscribeInput!) {
    result: subscribe(input: $input) { token subscriptionId finalPrice }
  }`)

  const paypalMutation = new Mutation<PaypalToken>(`
    mutation($id: ID!, $email: String!, $activateNow: Boolean, $coupon: String, $returnPath: String, $currency: String!) {
      token: paypalToken(
        id: $id, email: $email,
        activateNow: $activateNow, coupon: $coupon,
        returnPath: $returnPath, currency: $currency
      )
    }
  `)

  const [loading, setLoading] = useState<boolean>(false)

  const submit = (input: Hash) => {
    subscribeMutation.exec({ input }).then(
      action(() => {
        const result = subscribeMutation.data?.result
        if (result) {
          Notifications.success('Account created successfully')
          Session.authenticated = true
          Session.accessToken = result.token
          purchaseFinished && purchaseFinished()

          const params: string[] = []
          if(Router.qs.paypal) {
            const code = (Router.qs.paypal as string).split('|')[1] as string
            if (code) {
              params.push(`coupon=${code}`)
            }
          } else if(Router.qs.coupon) {
            params.push(`coupon=${Router.qs.coupon as string}`)
          } else if(coupon) {
            params.push(`coupon=${coupon.id}`)
          }

          if(activateNow) {
            params.push(`activateNow=${activateNow}`)
          }
          localStorage.removeItem('vs_checkout_cache')
          Router.redirect(`/welcome${params.length > 0 ? `?${params.join('&')}` : ''}`)
        } else {
          setLoading(false)
          const error = subscribeMutation.error()
          if (error.includes(ZIP_ERROR_MATCHER)) {
            Notifications.error(ZIP_ERROR_MESSAGE, { timing: 8000 })
          } else {
            Notifications.error(error, { timing: 8000 })
          }
        }
      })
    )
  }

  const submitInput = (): Hash => ({
    type, priceId, activateNow, promotion,
    email: paymentData.email,
    affiliateId: cookies.get('affiliateId') || '',
    clickId: cookies.get('clickId') || '',
    pubId: cookies.get('pubId') || '',
    signupSource: location.pathname.split('/')[1],
    currency: resolveCurrency(),
  })

  const submitCard = (td: CardToken) => {
    submit({
      ...submitInput(),
      email: paymentData.email,
      firstName: paymentData.firstName,
      lastName: paymentData.lastName,
      zip: paymentData.zip,
      couponCode: coupon?.id || '',
      card: { token: td.token },
    })
  }

  const submitPayPal = (token: string, couponCode?: string) => {
    submit({
      ...submitInput(),
      zip: '0000',
      couponCode: coupon?.id || couponCode || '',
      paypal: { token },
    })
  }

  const handleChargebeeJs = () => {
    const cardTokenizer = cardRef.current
    cardTokenizer.tokenize().then((cardToken: CardToken) => {
      submitCard(cardToken)
    }).catch(() => {
      Notifications.error('The card information is invalid')
      setLoading(false)
    })
  }

  const handleSubmit = () => {
    if(loading) {
      return
    }

    const captchaTokenizer = captchaRef.current
    captchaTokenizer.executeAsync().then((token: string) => {
      captchaTokenizer.reset()
      if (token && type === 'CARD') {
        setLoading(true)
        handleChargebeeJs()
      }
    })
  }

  const handlePayPal = () => {
    if(loading) {
      return
    }

    setLoading(true)
    const payload = {
      id: priceId,
      activateNow,
      email: paymentData.email,
      coupon: coupon?.name || '',
      returnPath: paypalReturnPath,
      currency: resolveCurrency(),
    }

    paypalMutation.exec(payload).then(() => {
      const token = paypalMutation.data?.token
      if(token) {
        window.location.href = `${PAYPAL_URL}${token}`
      } else {
        setLoading(false)
        Notifications.error(paypalMutation.error(), { timing: 8000 })
      }
    })
  }

  useEffect(() => {
    if(!Router.qs.paypal || (!coupon && !!Router.qs.coupon)) {
      return
    }

    if(loading) {
      return
    }

    const parts: string[] = (Router.qs.paypal as string).split('|')
    if (parts.at(-1) === 'completed') {
      setLoading(true)
      submitPayPal(Router.qs.token as string, parts.at(1))
    } else {
      Notifications.warning('The operation on PayPal was canceled.')
    }
  }, [coupon])

  const isCard = type === 'CARD'
  const paypalReady =
    paymentData.email !== undefined &&
    emailRegex.test(paymentData.email)

  const cardReady =
    paypalReady &&
    paymentData.zip !== undefined &&
    paymentData.zip !== '' &&
    paymentData.firstName !== undefined &&
    paymentData.firstName !== '' &&
    paymentData.lastName !== undefined &&
    paymentData.lastName !== ''

  return (
    <>
      {isCard && (
        <Button
          disabled={!cardReady || disabled}
          onClick={handleSubmit}
          content={activateNow ? 'Continue' : 'Start Free Trial'}
          color="red"
          size="huge"
          primary
          fluid
          loading={loading}
        />
      )}
      {!isCard && (
        <PaypalBtn
          disabled={!paypalReady || disabled}
          onClick={handlePayPal}
          loading={loading}
        />)}
    </>
  )
}

const oSubmit = observer(Submit)
export { oSubmit as Submit }
