/* eslint-disable complexity */
import {gql, useQuery} from '@apollo/client'
import {set} from 'dot-prop'
import React, {FC, useEffect, useRef, useState} from 'react'
import {Form, FormField, Grid, GridColumn, GridRow, Image, Label, Modal} from 'semantic-ui-react'
import {Plans} from '../../components/Plans'
import {SimpleBox} from '../../components/SimpleBox'
import {SimpleCard} from '../../components/SimpleCard'
import {SimpleForm} from '../../components/SimpleForm'
import {Pane, SimpleTab} from '../../components/SimpleTab'
import {SimpleText} from '../../components/SimpleText'
import {observer} from '../../decorators'
import {Discount} from '../../fragments/modal/payment/Discount'
import {Breakdown} from '../../fragments/payment/Breakdown'
import {Submit} from '../../fragments/payment/Submit'
import {toMoney} from '../../shared/format'
import Router from '../../shared/router'
import {ChargebeeItemPrice} from '../../shared/types'
import {Plan, PlanSellingPoint} from '../../type'
import {useNavigation} from '../../hooks/useNavigation'
import {DiscountWarning} from '../../fragments/modal/payment/DiscountWarning'
import {Coupon, FetchCoupon} from '../../utils/coupon'
import {Upsell} from '../../fragments/payment/Upsell'
import {ActivateNow} from '../../fragments/ActivateNow'
import {PaymentData} from '../../fragments/payment/ICardProps'
import {CardComponent} from '../../fragments/payment/CardComponent'
import {useSiteSetting} from '../../hooks/useSiteSetting'
import {trackBeginCheckout, trackEmailValidationSuccess, trackPurchase} from '../../utils/tracking'
import {parsePayPalReturnData} from '../../graphql/checkout'
import Session from '../../shared/storages/session'
import {emailRegex} from '../../const'

const PaymentPayPalIcon = require('~assets/images/icons/payment-paypal.svg')
const PaymentCardsImage = require('~assets/images/payment-cards.svg')
const PlanCheckMarkIcon = require('~assets/images/icons/plan-check-mark.svg')

const GQL_PLANS = gql`
  query ($id: ID!) {
    plan(id: $id) {
      id
      name
      externalName
      description
      price
      period
      periodUnit
      pricingModel
      currencyCode
      trialPeriod
      trialPeriodUnit
      billingCycles
      freeQuantity
      itemFamily { name }
      item { metadata }
      __typename
    }
  }
`

type PriceProperties = 'id' | 'name' | 'externalName' | 'price' | 'trialPeriod'
export type Price = Pick<ChargebeeItemPrice, PriceProperties>
type Result = { plan: Plan }

export type CardDetails = { number: string; expiry: string; cvv: string; token?: string }
type CouponWarningProps = {
  coupon: Coupon
  plan: Plan
}

type Props = {
  children?: React.ReactNode
  trialEnabled: boolean
  promotion: boolean
}

const CheckoutProduct: FC<Props> & { authorize: boolean; auth: string; } = props => {
  const forceAuthorizeOne = useSiteSetting('FORCE_AUTHORIZE_ON_ONE_PLANS')
  const cardRef = useRef<any>()
  const captchaRef = useRef<any>()
  const trialEnabled = props.trialEnabled && Router.qs.noTrial === undefined
  const { setNav, resetNav } = useNavigation()
  const price = Router.params.price as string
  const priceIds = price && price.includes(':') ? price.split(':') : [price]
  const [firstPlanId, setFirstPlanId] = useState(priceIds[0])
  const [firstLoading, setFirstLoading] = useState(true)
  const paypalData = parsePayPalReturnData()
  const [activateNow, setActivateNow] = useState<boolean>(paypalData.activateNow)
  const [coupon, setCoupon] = useState<Coupon | undefined>(undefined)
  const [couponWarningPayload, setCouponWarningPayload] = useState<CouponWarningProps>()
  const [type, setType] = useState<'CARD' | 'PAYPAL'>(Router.qs.paypal ? 'PAYPAL' : 'CARD')
  const [modal, setModal] = useState<'discount-warning' | undefined>(undefined)
  const [cardErrors, setCardErrors] = useState<string[]>([])
  const [data, setData] = useState<PaymentData>({
    email: paypalData.email.replace(/ /g, '+'),
    firstName: '',
    lastName: '',
    zip: '',
    card: { number: '', expiry: '', cvv: '' },
    paypal: {},
    captcha: false,
  })

  useEffect(() => {
    if(firstLoading){
      setFirstLoading(false)
      const checkoutDataCache = JSON.parse(localStorage.getItem('vs_checkout_cache') ?? '{}')
      if (Object.keys(checkoutDataCache).length > 0) {
        setData(checkoutDataCache.data)
        setType(checkoutDataCache.type)
        if (checkoutDataCache.coupon) {
          setCoupon(checkoutDataCache.coupon)
        }
        if (checkoutDataCache.activateNow !== undefined) {
          setActivateNow(checkoutDataCache.activateNow)
        }
      }
    }
    setNav('no-sign-in')
    return () => {
      resetNav()
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('vs_checkout_cache',
      JSON.stringify({id:firstPlanId, coupon, activateNow, data, type}))
  }, [data, coupon, firstPlanId, activateNow, type])

  const { data: planData, loading } = useQuery<Result>(GQL_PLANS, { variables: { id: firstPlanId } })

  const discount = () => {
    if (!coupon?.discountType) {
      return 0
    } else if (coupon.discountType === 'FIXED_AMOUNT') {
      return Number(coupon.discountAmount)
    } else {
      return parseFloat(planData?.plan.price || '0') * Number(coupon.discountPercentage)
    }
  }

  const handleTabChange = (index: number) => {
    setType(index === 0 ? 'CARD' : 'PAYPAL')
  }

  const handleDataChange = ({ name, value }: { name: string; value: any }) => {
    setData(d => ({ ...set(d, name, value) }))
  }

  const handleEmailChange = (value: string) => {
    const regex = emailRegex
    const isValid = regex && regex.test(value)
    if (isValid) {
      trackEmailValidationSuccess()
    }

    setData(d => ({ ...set(d, 'email', value) }))
  }
  const plan = planData?.plan
  const isFamily = plan?.name.toLowerCase().includes('family')
  const months = (plan?.period || 0) * (plan?.periodUnit === 'YEAR' ? 12 : 1)
  const isOne = plan?.item?.metadata?.product === 'one'
  const isPlus = plan?.item?.metadata?.product === 'vpn-plus'

  const points: PlanSellingPoint[] = isPlus ? [
    { icon: PlanCheckMarkIcon, title: 'Unlimited Devices & Bandwidth' },
    { icon: PlanCheckMarkIcon, title: 'Malware Blocking' },
    { icon: PlanCheckMarkIcon, title: 'Ad Blocking' },
    { icon: PlanCheckMarkIcon, title: 'Unrestricted Streaming' },
    { icon: PlanCheckMarkIcon, title: 'Priority Phone Support' },
    { icon: PlanCheckMarkIcon, title: 'SSN Monitoring with Real-Time Alerts' },
  ] : [
    { icon: PlanCheckMarkIcon, title: '30 Day Free Trial' },
    { icon: PlanCheckMarkIcon, title: `${trialEnabled ? '30' : '60'} Day Money-Back Guarantee` },
    { icon: PlanCheckMarkIcon, title: '24/7 Helpful Support' },
    { icon: PlanCheckMarkIcon, title: 'Unlimited Devices' },
    { icon: PlanCheckMarkIcon, title: 'VPN', subtitle: 'Internet Security' }
  ]

  if(isOne) {
    points.push({icon: PlanCheckMarkIcon, title: 'VPN+', subtitle: 'Ad & Malware blocking, more...'})
    points.push({icon: PlanCheckMarkIcon, title: 'ID Monitoring', subtitle: 'Identity Security'})
    points.push({icon: PlanCheckMarkIcon, title: '$1 million insurance'})
    points.push({icon: PlanCheckMarkIcon, title: 'Dark web scanning'})
    points.push({icon: PlanCheckMarkIcon, title: 'All new features and more!'})
    points.push({icon: PlanCheckMarkIcon, title: isFamily ? '5 users' : '1 user'})
  } else {
    points.push({icon: PlanCheckMarkIcon, title: isFamily ? '5 users' : '1 user'})
  }

  useEffect(() => {
    setActivateNow(!trialEnabled)
  }, [trialEnabled])

  useEffect(() => {
    plan?.currencyCode && (Session.currency = plan?.currencyCode)
  }, [plan?.currencyCode])

  activateNow && points.shift()

  const priceTotal = (plan?.price || 0) - discount()

  const breakdownMarkup = (
    <>
      <Breakdown
        total={toMoney(priceTotal)}
        price={plan?.price || 0}
        coupon={coupon}
        activateNow={activateNow}
      />
      {trialEnabled && <ActivateNow
        months={months}
        activateNow={activateNow}
        setActivateNow={setActivateNow}
      />}
    </>
  )

  const paymentPanes: Pane[] = [
    {
      type: 'CARD',
      icon: PaymentCardsImage,
      title: 'Credit Card',
      render: <CardComponent
        data={data}
        forwardRef={cardRef}
        captchaRef={captchaRef}
        onChange={handleDataChange}
        onErrors={setCardErrors}
        forceAuthorize={isOne && forceAuthorizeOne.enabled}
      />,
    },
    {
      type: 'PAYPAL',
      icon: PaymentPayPalIcon,
      title: 'PayPal',
      render: <></>,
    },
  ]

  const tagsMarkup = (version: string) => (
    <SimpleBox display="inline-block" ml="auto" className={version}>
      <Label.Group color="grey" size="large">
        {!activateNow && (
          <Label>
            <Image as="img" src={PlanCheckMarkIcon} height={20} width={20} />
            30 Day Free Trial
          </Label>
        )}
        <Label>
          <Image as="img" src={PlanCheckMarkIcon} height={20} width={20} />
          {trialEnabled ? '30' : '60'} Day Money-Back Guarantee
        </Label>
      </Label.Group>
    </SimpleBox>
  )

  const discountFormMarkup = (version: 'mobile' | 'desktop') => (
    <div className={`discountForm-${version}`}>
      <Discount
        planId={firstPlanId}
        onCouponFound={(d: Coupon) => {
          setCoupon(d)
          if (plan && d) {
            trackBeginCheckout(plan, isFamily, d, 'add coupon')
          }
        }}
        onRemoveCoupon={() => {
          setCoupon(undefined)
          if(plan) {
            trackBeginCheckout(plan, isFamily, undefined, 'remove coupon')
          }
        }}
      />
    </div>
  )

  const linkMarkup = (path: string, text: string) => (
    <a href={`https://virtualshield.com/${path}`} target="_blank" rel="noreferrer"> {text}</a>
  )

  const onChangePlan = (selectedPlan: Plan) => {
    FetchCoupon(selectedPlan?.id, coupon?.id).then(loadedCoupon => {
      const path = location.pathname.split('/')[1]
      Router.updateHistory(loadedCoupon ? `/${path}/${selectedPlan.id}?coupon=${loadedCoupon.id}` : `/${path}/${selectedPlan.id}`)
      setFirstPlanId(selectedPlan.id)
      loadedCoupon && setCoupon(loadedCoupon)
      if (selectedPlan.period === 1 && selectedPlan.periodUnit === 'MONTH' && activateNow) {
        setActivateNow(false)
      }
    }).catch(() => {
      if (coupon) {
        setCouponWarningPayload({ coupon: coupon, plan: selectedPlan })
        setModal('discount-warning')
      }
    })
  }

  const applyWarnedCoupon = () => {
    if (couponWarningPayload) {
      const path = location.pathname.split('/')[1]
      Router.updateHistory(`/${path}/${couponWarningPayload.plan.id}`)
      setFirstPlanId(couponWarningPayload.plan.id)
      setCoupon(undefined)
      setModal(undefined)
    }
  }

  const buttonDisabled = type === 'CARD' && cardErrors.length > 0

  if (!plan && !loading) {
    Router.redirect('/pricing')
  }

  useEffect(() => {
    if (plan && !Router.qs.coupon) {
      trackBeginCheckout(plan, isFamily, coupon, 'plan')
    }
  }, [plan])

  useEffect(() => {
    if (coupon && plan && Router.qs.coupon) {
      trackBeginCheckout(plan, isFamily, coupon, 'coupon')
    }
  }, [coupon])

  return (
    <fieldset disabled={paypalData.result === 'completed'} style={{ margin: 0, padding: 0, border: 'none' }}>
      {/* modal */}
      <Modal closeIcon size="tiny" open={modal !== undefined} onClose={() => setModal(undefined)}>
        <DiscountWarning
          isOpen={modal === 'discount-warning'}
          payload={couponWarningPayload}
          onFinish={() => {
            applyWarnedCoupon()
          }}
        />
      </Modal>
      {/* page */}
      <SimpleBox mt={15} p={4} className="checkout">
        <div className="plan-selected">
          <span>Plan selected:<b> {plan?.externalName}</b></span>
        </div>
        <SimpleBox maxWidth={1240} mx="auto">
          <Form name={'checkout'}>
            {/* account */}
            {tagsMarkup('mobile')}
            <SimpleBox mt={2} display="flex" alignItems="center" gap={18} width="100%">
              <SimpleCard color="grey" style={{ flex: '0 0 40px' }} base>
                <SimpleBox height={40} width={40} display="grid" alignContent="center">
                  <SimpleText size="title1">1</SimpleText>
                </SimpleBox>
              </SimpleCard>
              <SimpleText size="large">
                Enter Your Email Address
                <span className="enter-email" />
              </SimpleText>
              {tagsMarkup('desktop')}
            </SimpleBox>
            <SimpleBox mt={4}>
              <SimpleCard fluid padding="heavy">
                <FormField>
                  <label htmlFor="email">Enter email address</label>
                  <Grid>
                    <GridRow verticalAlign="middle">
                      <GridColumn width="10">
                        <SimpleForm.Input
                          type="email"
                          value={data?.email}
                          onChange={(d: any) => handleEmailChange(d.value)}
                          size="large"
                          required
                          fluid
                          showValidation={true}
                        />
                      </GridColumn>
                      <GridColumn width="6">
                        <div className="text--x-small"><span className="text">Privacy Guarantee:</span> We do not share your
                         information and will contact you only as needed to provide our service.</div>
                      </GridColumn>
                    </GridRow>
                  </Grid>
                </FormField>
              </SimpleCard>
            </SimpleBox>
            {/* payment */}
            <SimpleBox mt={4} display="flex" alignItems="center" gap={18} width="100%">
              <SimpleCard color="grey" style={{ flex: '0 0 40px' }} base>
                <SimpleBox height={40} width={40} display="grid" alignContent="center">
                  <SimpleText size="title1">2</SimpleText>
                </SimpleBox>
              </SimpleCard>
              <SimpleText size="large">
                Select Your Preferred Payment Method
                <span className="payment-method" />
              </SimpleText>
            </SimpleBox>
            {discountFormMarkup('mobile')}
            <SimpleBox mt={2}>
              <div className="grid grid-checkout">
                <div className="grid-checkout-a">
                  <SimpleTab
                    key={type}
                    onChange={handleTabChange}
                    panes={paymentPanes}
                    initial={type === 'CARD' ? 0 : 1}
                    footer={
                      <>
                        {breakdownMarkup}
                        <SimpleBox mt={3} className="submit-box">
                          <Submit
                            paypalReturnPath={`/${location.pathname.split('/')[1]}`}
                            type={type}
                            data={data}
                            priceId={firstPlanId}
                            cardRef={cardRef}
                            coupon={coupon}
                            disabled={buttonDisabled}
                            activateNow={activateNow}
                            promotion={activateNow && props.promotion}
                            captchaRef={captchaRef}
                            purchaseFinished={() => plan && trackPurchase(plan, isFamily, activateNow, coupon)}
                          />
                        </SimpleBox>
                        <SimpleBox mt={3}>
                          <div className="text--x-small">
                            By signing up for our service, you agree to our
                            {linkMarkup('legal/terms/', 'Terms of Service')} and acknowledge our
                            {linkMarkup('legal/privacy/', 'Privacy Policy')}. To ensure uninterrupted access, your
                            subscription will automatically renew at the end of each billing cycle using the payment
                            method provided until you cancel it by disabling auto-renewal,
                            which can be done at any time.
                          </div>
                        </SimpleBox>
                      </>
                    }
                  />
                </div>
                {plan && (
                  <div className="grid-checkout-b">
                    {discountFormMarkup('desktop')}
                    <br />
                    <SimpleCard title="Selected Plan">
                      <Plans.Single
                        plan={plan}
                        coupon={coupon}
                        points={points}
                        price={priceTotal}
                        onChangePlan={onChangePlan}
                        showPlanSelection={paypalData.result !== 'completed'}
                        hidePrices
                      />
                    </SimpleCard>
                    <Upsell currentPlan={plan} onUpgrade={onChangePlan} coupon={coupon} />
                    <Plans.Trial trialEnabled={!activateNow && trialEnabled} />
                  </div>
                )}
              </div>
            </SimpleBox>
          </Form>
        </SimpleBox>
      </SimpleBox>
    </fieldset>
  )
}

CheckoutProduct.defaultProps = {
  trialEnabled: true,
  promotion: true
}

CheckoutProduct.authorize = false
CheckoutProduct.auth = '/apps'

export default observer(CheckoutProduct)
